<!--地标书画展和协会荣誉通用详情-->
<template>
  <div class="main-div">
    <!--面包屑-->
    <div class="breadTopDiv">
      <el-row class="breadTop">
        <el-col>
          <top-path-list :path-obj="pathObj"></top-path-list>
        </el-col>
      </el-row>
    </div>
    <!--内容部分-->
    <div class="content-div">
      <div class="titleDiv">
        <el-row>
          <el-col :span="24">
            <span class="news_title">{{title}}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <span class="news_time_reader">{{time}}</span>
            <!--<span class="news_time_reader" style="margin-left: 20px">{{reader}}</span>-->
          </el-col>
        </el-row>
      </div>
      <el-row>
        <el-col :span="24" style="min-height: 248px">
          <div class="w-e-text-container">
            <div v-html="newsData" data-slate-editor></div>
          </div>
        </el-col>
      </el-row>
      <!--<el-row>
        <el-col :span="24" class="label_contain">
          <div class="news_labels">文章标签</div>
          <div>
            <div class="label_div">
              <span class="label_span">{{labelList}}</span>
            </div>
          </div>
        </el-col>
      </el-row>-->
    </div>
    <div class="bottom-div">
      <!--上一篇，下一篇-->
      <el-row>
        <el-col :span="24" class="rowSpaceBetween" style="margin-top: 20px">
          <div>
            <span class="prev_next_span">上一篇</span>
            <span v-if="previous.contentTitle" class="otherNewClass cursorPointer" @click="toPreOrNext(previous.contentId)">{{previous.contentTitle}}</span>
            <span v-else class="noneNewClass">没有了</span>
          </div>
          <div>
            <span class="prev_next_span">下一篇</span>
            <span v-if="nextNew.contentTitle" class="otherNewClass cursorPointer" @click="toPreOrNext(nextNew.contentId)">{{nextNew.contentTitle}}</span>
            <span v-else class="noneNewClass">没有了</span>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" class="editContent">
          <div class="editor">责任编辑：</div>
          <div class="editorName">
            {{contentEditor}}
          </div>
        </el-col>
      </el-row>
      <!--增加取消下方白条-->
      <div style="height: 44px"></div>
    </div>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import axios from 'axios'
export default {
  name: "paintingDetails",
  components: { topPathList },
  data() {
    return {
      pathObj: [],
      title: '',
      time: '',
      reader: '5080人看过',
      newsData: '',
      previous: {},
      nextNew: {},
      //labelList: ['地理标志', '地理标志协会', '地理标志产品', '地理标志商标', '山东地理标志'],
      labelList: "",
      contentId: "",
      contentEditor: "", //责任编辑
      typeId: "",
    }
  },
  mounted () {
    //将路由带过来的contentId和typeId存储在session中，用于面包屑跳转
    this.pathObj = this.$route.query.pathObj;
    this.contentId = this.$route.query.contentId; //文章id
    this.typeId = this.$route.query.typeId; //栏目id
    sessionStorage.setItem('paintId', JSON.stringify(this.contentId));
    sessionStorage.setItem('typeId', JSON.stringify(this.typeId));
    this.getDetail()
  },
  methods: {
    //根据文章id查询文章详情
    getDetail() {
      let params = {
        id: this.contentId,
        typeId: this.typeId
      }
      axios({
        method: "get",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/api/portal/graphicdisplay/queryById',
        params: params
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.title = res.data.data.graphicDisplays[0].contentTitle //标题
          this.time = res.data.data.graphicDisplays[0].contentEditTime //日期
          this.newsData = res.data.data.graphicDisplays[0].contentDetail //内容
          this.contentEditor = res.data.data.graphicDisplays[0].contentEditor //责任编辑
          //this.labelList = res.data.data.graphicDisplays[0].keyword //关键字
          this.previous.contentId = res.data.data.previousId //上一条id
          this.previous.contentTitle = res.data.data.previousName //上一条名字
          this.nextNew.contentId = res.data.data.nextId //下一条id
          this.nextNew.contentTitle = res.data.data.nextName //下一条名字

        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

    //上一篇
    toPreOrNext(contentId) {
      this.contentId = contentId
      this.getDetail()
      document.documentElement.scrollTop = 0;
    },

  },
}
</script>

<style scoped lang="less">
  @import '~@/assets/css/common.less';
  .main-div {
    background-color: #f8f9fa !important;
  }
  .breadTopDiv {
    text-align: center;
    .breadTop {
      width: 1200px;
      margin: auto;
    }
  }
  .content-div {
    min-height: 346px;
    margin: auto;
    padding: 32px 40px 12px 40px;
    width: 1120px;
    background: #FFFFFF;
    .titleDiv {
      text-align: center;
    }
  }
  .news_title {
    font-size: 32px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #333333;
    line-height: 48px;
    margin-top: 52px;
  }
  .news_time_reader {
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #888888;
    line-height: 21px;
  }
  .news_labels {
    font-size: 16px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: bold;
    color: #3E3A39;
    line-height: 24px;
  }
  .label_contain {
    display: flex;
    align-items: center;
  }
  .label_div {
    background: #E9F2FF;
    border-radius: 6px;
    margin: 5px;
    display: flex;
    float: left;

    .label_span {
      padding: 5px 10px;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #186CF5;
      line-height: 28px;
    }
  }
  .bottom-div {
    margin: auto;
    width: 1120px;
  }
  .prev_next_span {
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #3E3A39;
    line-height: 24px;
    margin-right: 10px;
  }
  .otherNewClass {
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #186CF5;
    line-height: 24px;
  }
  .noneNewClass {
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #B2B0B0;
    line-height: 24px;
  }
  .editContent {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #3E3A39;
    line-height: 21px;
  }
  ::v-deep .el-row {
    margin: 20px 0;
  }

  ::v-deep .temp_font {
    text-align: left;
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #3E3A39;
    line-height: 30px;
    text-indent: 2rem;
  }

  @media screen and (max-width: 1366px) {
    .main-div {
      background-color: #f8f9fa !important;
    }
    .breadTopDiv {
      text-align: center;
      .breadTop {
        width: 1200px;
        margin: auto;
      }
    }
    .content-div {
      margin: auto;
      padding: 32px 40px 12px 40px;
      width: 1120px;
      background: #FFFFFF;
      .titleDiv {
        text-align: center;
      }
    }
    .news_title {
      font-size: 32px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #333333;
      line-height: 48px;
      margin-top: 52px;
    }
    .news_time_reader {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #888888;
      line-height: 21px;
    }
    .news_labels {
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: bold;
      color: #3E3A39;
      line-height: 24px;
    }
    .label_contain {
      display: flex;
      align-items: center;
    }
    .label_div {
      background: #E9F2FF;
      border-radius: 6px;
      margin: 5px;
      display: flex;
      float: left;

      .label_span {
        padding: 5px 10px;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #186CF5;
        line-height: 28px;
      }
    }
    .bottom-div {
      margin: auto;
      width: 1120px;
    }
    .prev_next_span {
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #3E3A39;
      line-height: 24px;
      margin-right: 10px;
    }
    .otherNewClass {
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #186CF5;
      line-height: 24px;
    }
    .noneNewClass {
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #B2B0B0;
      line-height: 24px;
    }
    .editContent {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #3E3A39;
      line-height: 21px;
    }
    ::v-deep .el-row {
      margin: 20px 0;
    }

    ::v-deep .temp_font {
      text-align: left;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #3E3A39;
      line-height: 30px;
      text-indent: 2rem;
    }
  }
</style>
